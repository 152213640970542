import React, { useRef, useState, useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import "./style.css";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";

import { CalendarDots, MapPin } from "@phosphor-icons/react";

const ContentSlider = ({ onPlaying, start, setStart }) => {
  const startingHandler = () => {
    onPlaying();
    setStart(true);
  };
  return (
    <div className="absolute left-0 top-0 z-[10000] h-full w-full px-6 flex items-center">
      {start ? (
        <Swiper modules={Pagination} className="hero-content h-3/4  ">
          <SwiperSlide className="h-full overflow-auto">
            <div className="flex flex-col items-center justify-center text-white h-full">
              <h1 className="dancing text-6xl text-center">
                Fady <br /> & <br /> Christelle
              </h1>
              <p className="os-400 text-2xl mt-10">Are Getting Married</p>
            </div>
          </SwiperSlide>

          <SwiperSlide className="h-full overflow-auto ">
            <div className="flex flex-col items-center justify-between  text-white text-center h-full">
              <div className="text-3xl">
                <p className="dancing mb-2">Mounir and Nada Al-khalil</p>
                <p className="dancing">Joseph and Randa Sadaka</p>

                <p className="text-lg mt-10">
                  Request the honor of your presence at the wedding of their son
                  and daughter
                </p>
              </div>
              <div>
                <p className="dancing text-6xl">
                  Fady <br /> and <br />
                  Christelle
                </p>

                <span>
                  <p className="text-lg mt-8">Number of Guests: 1</p>
                </span>
              </div>

              <div>
                <p className="text-lg">on Sunday 08 of September, 2024</p>
              </div>
              <p className="opacity-0">0</p>
            </div>
          </SwiperSlide>

          <SwiperSlide className="h-full overflow-auto">
            <div className="flex flex-col items-center gap-y-14  text-white text-center  h-full">
              <p className="text-3xl os-600">Wedding Ceremony</p>

              <div className="flex flex-col items-center justify-center text-center">
                <CalendarDots size={32} />
                <span>September 08, 2024</span>
                <p>5:00 Pm</p>
              </div>
              <div className="flex flex-col items-center justify-center text-center">
                <MapPin size={32} />
                <span>Sayidat al-Nejat Cathedral</span>
                <p>Zahle</p>
              </div>

              <button className="border border-white px-8 py-3 rounded-md ">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://maps.app.goo.gl/SD57h4t8g8kmzEMq5"
                >
                  Check location
                </a>
              </button>
            </div>
          </SwiperSlide>

          <SwiperSlide className="h-full overflow-auto">
            <div className="flex flex-col items-center gap-y-14  text-white text-center  h-full">
              <p className="text-3xl os-600">Wedding Dinner</p>

              <div className="flex flex-col items-center justify-center text-center">
                <CalendarDots size={32} />
                <span>September 08, 2024</span>
                <p>8:00 Pm</p>
              </div>
              <div className="flex flex-col items-center justify-center text-center">
                <MapPin size={32} />
                <span>Nede Retaba</span>
                <p>Talya</p>
              </div>

              <button className="border border-white px-8 py-3 rounded-md ">
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://maps.app.goo.gl/wsEGnuPBPBT42mPh7"
                >
                  Check location
                </a>
              </button>
            </div>
          </SwiperSlide>

          <SwiperSlide className="h-full overflow-auto">
            <div className="flex flex-col items-center gap-y-14  text-white text-center  h-full">
              <p className="text-3xl os-600">Wedding List</p>

              <div className="flex flex-col items-center justify-center text-center ">
                <p className="text-xl">
                  Your presence is our biggest gift but if you wish,A Wedding
                  List is available at
                </p>
                <div>
                  <p className="font-medium text-3xl mt-10">Whish Money:</p>
                  <p className="text-xl mt-2">Fady et christelle</p>
                  <p className="text-xl ">Account ID: 20768485-03</p>
                </div>

                <p className="mt-16 mb-10 text-xl">
                  To Confirm or applogize please contact us before 20 august
                </p>

                <div className="">
                  <p className="text-lg mb-2">
                    Fady's Number:{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://wa.me/71121461"
                      className="underline"
                    >
                      71121461
                    </a>
                  </p>
                  <p className="text-lg">
                    Christelle's Number:{" "}
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href="https://wa.me/81121461"
                      className="underline"
                    >
                      81121461
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      ) : (
        <div className=" h-full w-full px-6 flex flex-col items-center text-white justify-center ">
          <button
            onClick={startingHandler}
            className="text-4xl w-32 h-32 rounded-full border border-white mx-auto dancing"
          >
            Start
          </button>

          <p className=" text-2xl os-400 mt-2">Tap to Start</p>
        </div>
      )}
    </div>
  );
};

export default ContentSlider;
